import React from "react";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import fireIcon from "../../assets/img/icon/fire.png";
import CountDownOne from "../CountDown/CountDownOne";

const Banner = (props) => {
  return (
    <section className="banner-area banner-bg">
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape01} alt="" className="img-two" style={{width: 50}}/>
        <img src={shape01} alt="" className="img-three"  style={{width: 150}} />
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h2 className="title">
                Transforme seus desafios em <span>soluções</span> digitais.
                
              </h2>
            </div>
            <div className="banner-progress-wrap">
              <ul>
                <li>Desafio</li>
                <li>Planejamento</li>
                <li>Solução</li>
              </ul>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "91.5%" }}
                  aria-valuenow="91.5"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <h4 className="title">
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
